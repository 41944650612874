@font-face {
  font-family: "PublicaSans-Bold";
  src: local("PublicaSans-Bold"),
    url("../../fonts/PublicaSans-Bold.otf") format("truetype");
}

@font-face {
  font-family: "PublicaSans-Light";
  src: local("PublicaSans-Light"),
    url("../../fonts/PublicaSans-Light.otf") format("truetype");
}

@font-face {
  font-family: "PublicaSans-Medium";
  src: local("PublicaSans-Medium"),
    url("../../fonts/PublicaSans-Medium.otf") format("truetype");
}

@font-face {
  font-family: "ZPublicaSans";
  src: local("ZPublicaSans"),
    url("../../fonts/ZPublicaSans.otf") format("truetype");
}

@font-face {
  font-family: "Lato-Thin";
  src: local("Lato-Thin"), url("../../fonts/Lato-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-ThinItalic";
  src: local("Lato-ThinItalic"),
    url("../../fonts/Lato-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Light";
  src: local("Lato-Light"), url("../../fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-LightItalic";
  src: local("Lato-LightItalic"),
    url("../../fonts/Lato-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Regular";
  src: local("Lato-Regular"),
    url("../../fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Italic";
  src: local("Lato-Italic"),
    url("../../fonts/Lato-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-BoldItalic";
  src: local("Lato-BoldItalic"),
    url("../../fonts/Lato-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"), url("../../fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Black";
  src: local("Lato-Black"), url("../../fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: "tuteeIcon";
  src: local("tuteeIcon"), url("../../images/tuteeIcon.tif") format("tuteeIcon");
}

html,
body,
#react-root {
  height: 100%;
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
}

/* --------------------------------------------------------------------------------
Container
-------------------------------------------------------------------------------- */

.container {
  padding: 20px;
}

.alert {
  box-sizing: border-box;
  padding: 15px 10px;
  margin: 15px 0;
  border: 2px solid #e01616;
  background-color: #efefef;
  border-radius: 3px;
  color: #e01616;
  font-size: 0.8rem;
}

.formPanel {
  box-sizing: border-box;
  width: 400px;
  max-width: 100%;
  padding: 25px;
  margin: 0 auto 20px;
  background-color: #efefef;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 0.4);
}

input {
  margin: 5px;
  margin-bottom: 10px;
}

.adminPageContainer {
  padding-left: 350px;
  padding-right: 50px;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 20px;
}

.cardFirstLastName {
  text-transform: capitalize;
}

.cardHead {
  background-color: #f9f9f9;
  display: flex;
  padding: 15px;
  border-radius: 8px;
  margin: 5px 0px 20px 0px;
  margin-bottom: 20px;
}

.cardHeadCol {
  display: flex;
  align-items: center;
}

.cardHeadCol p {
  margin: 0px;
}

.accordionHeader {
  width: 100% !important;
}

.cardButtons {
  display: flex;
}

.flaggedSubjectLanguage {
  display: flex;
  flex-wrap: wrap;
}

.flag {
  margin-right: 3px;
}

.subjectFlag {
  color: #45c6a2;
  margin-left: 3px;
}

.languageFlag {
  color: #093e48;
  margin-left: 3px;
}

.activationButton {
  margin-right: 7px;
}

.tuteeInfoSubjectsAndLanguages {
  display: flex;
  justify-content: space-between;
}

.tuteeInfoSubjectsAndLanguages h2 {
  margin-bottom: 0px;
}

.tuteeInfoSubjectsAndLanguages p {
  display: flex;
  align-items: center;
  font-size: 22px;
  margin-bottom: 0px;
}

.subjectAndLanguage {
  display: flex;
}

.subjectAndLanguage p {
  margin-right: 6px;
}

.accordion-item {
  border: 1px solid rgba(255, 255, 255, 0) !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

h1 {
  font-family: Lato-Regular;
}

h2 {
  font-family: Lato-Regular;
}

p {
  font-family: Lato-Regular;
}

h3 {
  font-family: Lato-Regular;
}

.logoutNameAndIcon {
  color: white;
  font-size: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  padding: 0px 20px;
  transition: 0.3s;
}

.logoutNameAndIcon:hover {
  background-color: #1898b5;
  transition: 0.3s;
}

.logoutNameAndIcon p {
  margin-bottom: 0px;
}

.signOutIcon {
  font-size: 27px;
}

.signOutName {
  font-size: 23px;
  margin-right: 8px;
  margin-top: 3px;
  text-transform: lowercase;
  font-family: PublicaSans-Light;
}

.profileAnswer {
  font-family: Lato-Regular;
  font-size: 20px;
}

.profileQuestion {
  font-family: Lato-Black;
  font-size: 16px;
  margin-bottom: 0px;
}

.profileFirstLastName {
  font-family: Lato-Black;
  font-size: 24px;
  text-transform: capitalize;
}

.col-3 {
  text-transform: capitalize;
}

.languagePill {
  background-color: #c8edf8a4;
  padding: 8px 20px;
  width: fit-content;
  border-radius: 30px;
  margin: 10px 10px 10px 0px;
}

.languagePillContainer {
  display: flex;
  margin-bottom: 10px;
  width: 50%;
  flex-wrap: wrap;
  margin-top: 10px;
}

.profileTitle {
  display: flex;
  align-items: baseline;
}

/* .subjectContainer {
  display: flex;
} */

.subjectPillFlagged {
  background-color: #bdebde83;
  padding: 8px 20px;
  width: fit-content;
  border-radius: 30px;
  /* margin-right: 10px; */
  /* margin-left: 10px; */
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.subjectPill {
  background-color: #eaeaeadd;
  padding: 8px 20px;
  width: fit-content;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.gradesPill {
  background-color: #eaeaeadd;
  padding: 8px 20px;
  width: fit-content;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Lato-Regular;
  margin-right: 10px;
}

.formInput {
  width: 40% !important;
}

.selectInput {
  width: 50% !important;
}

.subjectOptions {
  margin-bottom: 17px;
}

.textInput {
  width: 40% !important;
}

.quote {
  font-family: Lato-Italic;
  font-size: 20px;
}

.accordion-button:not(.collapsed) {
  color: #1895ae;
  background-color: #1aa3c22a;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordionCard {
  margin-bottom: 17px !important ;
}

.secondaryButton {
  background-color: white;
  color: #1895ae;
  border: 1px solid #1895ae;
  text-transform: capitalize;
}

.secondaryButton:hover {
  background-color: #e6f6fa;
  border: 1px solid #1895ae;
  color: #1895ae;
}

.secondaryButton:focus {
  background-color: #dff4fa;
  border: 1px solid #1895ae;
  color: #1895ae;
  outline: none;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.primaryButton {
  background-color: #1aa5c2;
  border: 1px solid #1aa5c2 !important;
  text-transform: capitalize;
}

.primaryButton:hover {
  background-color: #1895ae;
  color: white;
  border: 1px solid #1895ae !important;
}

.primaryButton:focus {
  background-color: #1895ae;
  border: 1px solid #1895ae;
  color: white;
  outline: none;
}

.matchButton {
  margin-left: 10px;
}

.tableHeads {
  font-family: Lato-Black;
}

.languageFilterDropdown {
  width: 180px !important;
}

.languageFilterContainer {
  margin-bottom: 20px;
}

/* .nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #1aa5c2;
}
.nav-link:hover {
  display: block;
  padding: 0.5rem 1rem;
  color: #1aa5c2;
} */

.calenderIcon {
  height: 18px;
  margin-bottom: 5px;
}

.tuteeInfo {
  position: relative;
  margin-bottom: 15px;
}

.tuteeInfoBackground {
  height: 20px;
  width: 375px;
  position: absolute;
  background-color: #1aa6c258;
  margin-top: 22px;
}

.tuteeSubject {
  position: relative;
  margin-bottom: 15px;
}

.tuteeSubjectBackground {
  height: 20px;
  width: 355px;
  position: absolute;
  background-color: #1aa6c258;
  margin-top: 22px;
}

.tuteeGeneralInfoRecordsSection {
  margin-bottom: 25px;
  margin-left: 20px;
}

.tuteeAdditionalInfo {
  position: relative;
  margin-bottom: 15px;
}

.tuteeAdditionalInfoBackground {
  height: 20px;
  width: 325px;
  position: absolute;
  background-color: #1aa6c258;
  margin-top: 22px;
}

.tuteeSubjectRecordsSection {
  margin-bottom: 25px;
  margin-left: 20px;
}

.tuteeAdditionalInfoRecordsSection {
  margin-bottom: 25px;
  margin-left: 20px;
}

.tutorTuteeBorder {
  border-left: 2px solid #1895ae3a;
}

.tutorGeneralInfo {
  position: relative;
  margin-bottom: 15px;
}

.tutorAdditionalInfoBackground {
  height: 20px;
  width: 370px;
  position: absolute;
  background-color: #1aa6c258;
  margin-top: 22px;
}

.tutorGeneralInfoSection {
  margin-bottom: 25px;
  margin-left: 20px;
}

.tutorSubjectGradePreferenceBackground {
  height: 20px;
  width: 475px;
  position: absolute;
  background-color: #1aa6c258;
  margin-top: 22px;
}

.tutorSubjectGradePreference {
  position: relative;
  margin-bottom: 15px;
}

.tutorAdditionalInfo {
  position: relative;
  margin-bottom: 15px;
}

.tutorSubjectGradePreferenceSection {
  margin-bottom: 25px;
  margin-left: 20px;
}

.gradesPillContainer {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.subjectPillContainer {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.tutorContainer {
  padding-left: 10px;
}

.cardHeadCol {
  padding: 0px;
}

.subjectAndLanguageDiv {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 20px;
  text-transform: capitalize;
}

.tutorTuteeHr {
  margin-top: 60px;
  margin-bottom: 60px;
}

.cardSubjects {
  display: flex;
}

/* -----------Forms Header------------- */

.headerDiv {
  background-color: white;
  padding-top: 10px;
}

.headerDiv h1 {
  margin: 0px;
  margin-left: 10px;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  padding-bottom: 15px;
  padding-top: 10px;
}

.headingLRT {
  font-family: Lato-Bold;
  font-size: 27px;
  margin-left: 20px;
}

.LTRRegFormHeader h2 {
  font-size: 18px;
  margin-bottom: 0px;
}

.brandNameHeader {
  display: flex;
  align-items: center;
}

/*---- forms ----*/

.formBackground {
  background-color: #ecf0f1;
  padding-bottom: 20px;
  min-height: 100vh;
}

.formContainer {
  background-color: white;
  margin-top: 50px;
  margin-bottom: 30px;
  border-radius: 20px;
  padding-bottom: 80px;
}

.formContent {
  font-family: Lato-regular;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 50px;
}

.formContent p {
  font-size: 18px;
  font-family: Lato-regular;
}

.formQandA {
  margin-bottom: 45px;
}

label {
  text-transform: initial;
  color: #70757a;
}

.customCheckAndRadioOptions {
  font-size: 16px;
  margin-bottom: 6px;
}

.firstLastName {
  display: flex;
}

.firstName {
  margin-right: 15px;
}

.requiredField {
  color: red;
}

.subP {
  font-size: 15px !important;
}

.selectGradeDropdown {
  width: 27.2em;
}

.saveAndContinueButton {
  width: 100%;
  height: 50px;
  text-transform: none;
  font-size: 20px;
  margin-top: 20px;
}

.saveAndContinueButton:disabled {
  background-color: gray;
  border: none !important;
  color: white;
  outline: none;
  width: 100%;
  height: 50px;
  font-size: 20px !important;
}

.rightarrow {
  position: relative;
  left: 435px;
}

.form-check-input {
  margin-top: 4.5px;
}

.form-control {
  margin-left: 0px;
}

.pWithSubP {
  margin-bottom: 0px;
}

.textArea {
  resize: none;
  width: 500px;
}

.additionalTextArea {
  height: 200px !important;
  width: 1080px !important;
}

.okButton {
  border-radius: 20px !important;
  width: 100px;
}

.okButton a {
  text-decoration: none !important;
  color: white !important;
}

.submissionModalFooter {
  display: flex;
  justify-content: center;
  border-top: none;
  padding-bottom: 20px;
}

.submissionModalHeader {
  border-bottom: none;
  padding-bottom: 0px;
}

.checkIcon {
  font-size: 110px;
  display: flex;
  color: #6bc4d7;
  justify-content: center;
}

.submissionModalH1 {
  font-family: Lato-regular;
  font-size: 36px;
}

.submissionModalTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 20px;
}

.customLabel {
  color: black;
  font-size: 18px;
  margin-bottom: 16px;
}

.tabContainer {
  padding-top: 20px;
}

/* ------radio buttons--------- */

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #45c6a2;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #45c6a2;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #45c6a2;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #45c6a2;
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #45c6a2;
}

.form-check-input:checked ~ .form-check-label {
  color: black;
}

/* ------checkbox--------- */

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #45c6a2;
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #45c6a2;
}

.form-check-input:checked {
  background-color: #45c6a2;
  border-color: #45c6a2;
}
