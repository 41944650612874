.loginPage {
  background-image: url(../../images/adminSigninBackground.png);
  background-size: cover;
  min-height: 100vh;
}

.adminLogo {
  height: 150px;
  position: absolute;
  left: 40px;
  top: 40px;
}

.adminLoginHeader {
  padding-left: 20px;
  height: 10vh;
}

.loginFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90vh !important;
  justify-content: center;
}

.loginP {
  font-family: Lato-Regular;
  font-size: 18px;
}

.loginH2 {
  font-family: Lato-Bold;
}

.loginPagePrimaryBtn {
  color: white;
  width: 100%;
  margin: 0px;
  margin-top: 25px;
  font-family: Lato-Regular;
}
