/* .nav { */
/* flex box layout */
/* display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 0 30px;
  background-color: #00acb0;
  overflow: hidden;
} */

/* .nav a {
  text-decoration: none;
}

.nav-title {
  font-size: 24px;
  font-weight: 700;
  color: #f2f2f2;
  padding-left: 10px;
  margin: 0;
} */

/* .navLink {
  display: inline-block;
  color: #f2f2f2;
  background-color: #00acb0;
  text-align: center;
  padding: 24px 10px;
  text-decoration: none;
  font-size: 15px; */

/* these last two make the logout button in
  the nav bar look like another link */
/* border: none;
  cursor: pointer;
  outline: 0;
} */
/* 
.navLink:hover {
  background-color: #008183;
} */

.ProgressStepDiv {
  height: 80px;
  width: 80px;
  /* border: 1px solid black; */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PublicaSans-Bold;
  font-size: 30px;
  padding-top: 7px;
  background-color: #d0f2fa;
  color: #22c2e5;
  text-decoration: none;
  position: relative;
}

.navContent p {
  color: #22c2e5;
  font-size: 15px !important;
  margin-top: 5px;
}

.nav-link {
  color: #22c2e5;
}

.nav-link:hover {
  color: #1da8c7;
}

.checkedNavLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
}

.checkedNavLink div {
  background-color: #22c2e5;
  color: #f0f9fa;
}

.NavItem {
  display: flex;
  align-items: center;
}

/* .navLine {
  border-bottom: 3px solid #22c2e5;
  width: 10em;
  height: 0px;
  margin-bottom: 50px;
} */

.navBarContainer {
  display: flex;
  justify-content: space-between;
}

.navCol {
  display: flex;
}

.navContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navLineTwo {
  width: 95%;
  margin-left: 20px;
  border-top: 3px solid #22c2e5;
  position: relative;
  top: 50px;
}
