/*  */
.modal-body {
  padding: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}
#modalFoot {
  padding: 35px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
p {
  font-family: inherit;
  font-size: 16px;
}

#tutorEmail,
#tuteeEmail,
#adultEmail {
  max-height: 300px;
  overflow-y: auto;
  padding-top: 15px;
  padding-right: 15px;
}
.fixed {
  position: fixed;
  background-color: white;
  padding-bottom: 10px;
  margin-bottom: 20px;
  padding-right: 75px;
}

.bold {
  font-weight: bold;
}

.mb-3 {
  margin-bottom: 0px !important;
}

.tuteeMisc {
  font-family: Lato-BoldItalic;
}

.tuteeSubjects {
  font-family: Lato-BoldItalic;
}

.schoolName {
  text-transform: capitalize;
}

.copyButton {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.copyBtnDiv {
  width: max-content;
}
